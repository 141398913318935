import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Image, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { APIContext } from "../../context/api";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import confirm from "../confirm";
import { AlertContext } from "../../context/alert";


const clusterParamsHelp = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">
      Cluster params
    </Popover.Header>
    <Popover.Body>
      <p>If selected cluster has no saved min/max, plot will use min/max from real values.
        Min/max needs to be set in order to make Capacity Management works.</p>
      <p>
        <b>Cluster params are not bound to a particular curve.</b>
        {` `}They are used to build schedule based on a currently active curve.
      </p>
    </Popover.Body>
  </Popover>
);


function EvaluateCurvePage() {
  let [searchParams, setSearchParams] = useSearchParams();

  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - 2);
  const defaultToDate = new Date();
  defaultToDate.setDate(defaultToDate.getDate() - 1);

  const initialFromDate = searchParams.get('fromDate') ? new Date(searchParams.get('fromDate')) : defaultFromDate;
  const initialToDate = searchParams.get('toDate') ? new Date(searchParams.get('toDate')) : defaultToDate;

  const defaultNewClusterParams = {
    min_capacity: searchParams.get('min_capacity') || null,
    max_capacity: searchParams.get('max_capacity') || null,
    buffer: searchParams.get('buffer') || null,
  };

  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const { showAlertMessage } = useContext(AlertContext);

  const [allCurves, setAllCurves] = useState([]);
  const [allClusters, setAllClusters] = useState([]);
  const [selectedCurveId, setSelectedCurveId] = useState(searchParams.get('curveId') || '');
  const [selectedCompareCurveId, setSelectedCompareCurveId] = useState(searchParams.get('compareCurveId') || '');
  const [selectedPrediction, setSelectedPrediction] = useState('');
  const [selectedCluster, setSelectedCluster] = useState(searchParams.get('cluster') || '');
  const [selectedClusterParams, setSelectedClusterParams] = useState({});
  const [newClusterParams, setNewClusterParams] = useState(defaultNewClusterParams);
  const [evaluateResult, setEvaluateResult] = useState('');
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [showDiffPercent, setShowDiffPercent] = useState(false);
  const [showFillBetween, setShowFillBetween] = useState(false);
  const [curveToCapacity, setCurveToCapacity] = useState(false);

  const loadCurves = useCallback(() => {
    return fetchAPI({
      path: '/list_curves', query: {}
    }).then(response => {
      setAllCurves(response.curves);
      return response?.curves || [];
    });
  }, []);

  const loadClusters = () => {
    return fetchAPI({
      path: '/list_clusters', query: {}
    }).then(response => {
      const items = response?.clusters || [];
      setAllClusters(items);
      return items;
    });
  };

  const evaluateCurve = () => {
    const fromDateDate = fromDate.toISOString().substring(0, 10);
    const toDateDate = toDate.toISOString().substring(0, 10);
    const minCapacity = parseInt(newClusterParams?.min_capacity ?? selectedClusterParams?.min_capacity);
    const maxCapacity = parseInt(newClusterParams?.max_capacity ?? selectedClusterParams?.max_capacity);
    const buffer = parseInt(newClusterParams?.buffer ?? selectedClusterParams?.buffer);
    fetchAPI({
      path: '/evaluate_curve',
      data: {
        from_date: fromDateDate,
        to_date: toDateDate,
        cluster: selectedCluster,
        curve_id: selectedCurveId || null,
        compare_to_curve_id: selectedCompareCurveId || null,
        buffer: buffer ?? null,
        min_capacity: minCapacity ?? null,
        max_capacity: maxCapacity ?? null,
        prediction_algorithm: selectedPrediction || null,
        show_diff_percent: !!showDiffPercent,
        show_fill_between: !!showFillBetween,
        curve_to_capacity: !!curveToCapacity,
      },
    }).then(response => {
      if (!response?.success) return;
      setEvaluateResult(response);
      setSearchParams({
        fromDate: fromDateDate,
        toDate: toDateDate,
        curveId: selectedCurveId,
        compareCurveId: selectedCompareCurveId,
        cluster: selectedCluster,
        buffer: newClusterParams?.buffer ?? '',
        min_capacity: newClusterParams?.min_capacity ?? '',
        max_capacity: newClusterParams?.max_capacity ?? '',
      });
    });
  };

  const updateClusterParams = () => {
    return fetchAPI({
      path: '/save_cluster_params',
      query: {
        cluster: selectedCluster,
      },
      data: {
        min_capacity: newClusterParams?.min_capacity ?? null,
        max_capacity: newClusterParams?.max_capacity ?? null,
      },
    }).then((response) => {
      setSelectedClusterParams(response.cluster_params);
      return response;
    });
  };

  const saveNewParams = async () => {
    const result = await confirm({
      confirmation: `
        Are you sure you override params for cluster <code>${selectedCluster}</code>?
        This will make capacity of cluster to be changed.
        <br/><br/>
        Type cluster name to confirm.
      `,
      input: true,
    });
    if (result !== selectedCluster) return;
    updateClusterParams().then((response) => {
      showAlertMessage({ message: `Params for cluster ${selectedCluster} updated` });
    });
  };

  useEffect(() => {
    if (!selectedCluster) {
      setSelectedClusterParams({});
      return;
    }
    fetchAPI({
      path: '/get_cluster_params',
      query: {
        cluster: selectedCluster,
      },
      noLoading: true,
    }).then(response => {
      if (!response?.success) return;
      setSelectedClusterParams(response.cluster_params);
    });
  }, [selectedCluster]);

  useEffect(() => {
    loadCurves().then((curves) => {
      curves?.forEach((item) => {
        if (item.is_active && !selectedCurveId) {
          setSelectedCurveId((item.id));
        }
      });
    });
    loadClusters();
  }, [loadCurves]);

  useEffect(() => {
    // generate on load
    if (selectedCurveId && searchParams.get('fromDate')) {
      evaluateCurve();
    }
  }, []);

  // useEffect(() => {
  //   if (!selectedCluster) {
  //     setNewClusterParams({});
  //   }
  // }, [selectedCluster]);

  let crontabToRender = null;
  if (evaluateResult?.curves_as_crontab && evaluateResult?.curves_as_crontab[selectedCurveId]?.length) {
    crontabToRender = evaluateResult.curves_as_crontab[selectedCurveId];
  }

  return (
    <>
      <Row className={'mb-4'}>
        <Col lg={8} md={8}>
          <p>See how actual meetings count fits curve pattern for a particular cluster. Update min/max per cluster</p>
        </Col>
        <Col>
          <div className={'float-end'}>
            {/*{selectedCluster && (*/}
            {/*  <Button*/}
            {/*    disabled={isAPILoading}*/}
            {/*    className={'me-2'}*/}
            {/*    variant={'info'}*/}
            {/*    onClick={saveNewParams}>*/}
            {/*    Save new params*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button
              disabled={isAPILoading}
              onClick={evaluateCurve}>
              Generate plot
            </Button>
          </div>
        </Col>
      </Row>
      <Row className={'mb-5'}>
        <Col>
          <h6>Curve:</h6>
          <Form.Select
            size="md"
            value={selectedCurveId || ''}
            onChange={(e) => setSelectedCurveId(e.target.value)}
            className={'mb-2'}
          >
            <option value="">-</option>
            {allCurves?.map((item => (
              <option value={item.id} key={item.id}>
                {item.is_active && ("[Active] ")}
                {item.name}
              </option>
            )))}
          </Form.Select>
          <h6>Compare to:</h6>
          <Form.Select
            size="md"
            value={selectedCompareCurveId || ''}
            onChange={(e) => setSelectedCompareCurveId(e.target.value)}
            className={'mb-2'}
          >
            <option value="">-</option>
            {allCurves?.filter((item) => item.id.toString() !== selectedCurveId).map((item => (
              <option value={item.id} key={item.id}>
                {item.is_active && ("[Active] ")}
                {item.name}
              </option>
            )))}
          </Form.Select>
          <Form.Check
            type="switch"
            id="show_diff_percent"
            label="Show diff percent"
            checked={showDiffPercent}
            onChange={() => setShowDiffPercent(!showDiffPercent)}
          />
          <Form.Check
            type="switch"
            id="show_fill_between"
            label="Fill between"
            checked={showFillBetween}
            onChange={() => setShowFillBetween(!showFillBetween)}
          />
          {/*<Form.Check*/}
          {/*  type="switch"*/}
          {/*  id="curve_to_capacity"*/}
          {/*  label="Convert curve to capacity"*/}
          {/*  checked={curveToCapacity}*/}
          {/*  onChange={() => setCurveToCapacity(!curveToCapacity)}*/}
          {/*/>*/}
        </Col>
        <Col>
          <h6>From date:</h6>
          <DatePicker
            className={'form-control mb-2'}
            dateFormat="yyyy-MM-dd"
            maxDate={toDate}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}/>
          <h6>To date:</h6>
          <DatePicker
            className={'form-control mb-2'}
            dateFormat="yyyy-MM-dd"
            minDate={fromDate}
            maxDate={new Date()}
            selected={toDate}
            onChange={(date) => setToDate(date)}/>

          {/* currently, only available for single day */}
          {((fromDate && toDate) && (fromDate.toDateString() === toDate.toDateString())) && (
            <div>
              <h6>Prediction algorithm:</h6>
              <Form.Select
                size="md"
                value={selectedPrediction || ''}
                onChange={(e) => setSelectedPrediction(e.target.value)}
                className={'mb-2'}
              >
                <option value="">-</option>
                <option value="predict_on_latest_trend_v1">predict_on_latest_trend_v1</option>
              </Form.Select>
            </div>
          )}
        </Col>
        <Col>
          <h6>
            Cluster (stack):
            {/*<OverlayTrigger trigger="click" placement="right" overlay={clusterParamsHelp}>*/}
            {/*  <Button variant={'link'} size="sm" className={'help-btn'}>?</Button>*/}
            {/*</OverlayTrigger>*/}
          </h6>
          <Form.Select
            size='md'
            value={selectedCluster || ''}
            onChange={(e) => setSelectedCluster(e.target.value)}
            className={'mb-2'}
          >
            <option value={''}>All stacks in region</option>
            {allClusters.map((item, i) => (
              <option key={i} value={item.name}>{item.name}</option>
            ))}
          </Form.Select>
          {/*{selectedCluster && (*/}
          {/*  <>*/}
          {/*    <p className={'text-muted m-0'}>*/}
          {/*      Cluster params*/}
          {/*    </p>*/}
          {/*    <span className={'pe-2'}>min_capacity: {selectedClusterParams?.min_capacity ?? 'Not set'}</span>*/}
          {/*    <span>max_capacity: {selectedClusterParams?.max_capacity ?? 'Not set'}</span>*/}
          {/*  </>*/}
          {/*)}*/}
        </Col>
        <Col>
          <div>
            <div className={'mb-2'}>
              <h6>min_capacity</h6>
              <Form.Control
                type="number"
                placeholder="Min capacity"
                value={newClusterParams?.min_capacity ?? ''}
                onChange={(e) => setNewClusterParams({
                  ...newClusterParams,
                  min_capacity: e.target.value && parseInt(e.target.value),
                })}/>
            </div>
            <div className={'mb-2'}>
              <h6>max_capacity</h6>
              <Form.Control
                type="number"
                placeholder="Max capacity"
                value={newClusterParams?.max_capacity ?? ''}
                onChange={(e) => setNewClusterParams({
                  ...newClusterParams,
                  max_capacity: e.target.value && parseInt(e.target.value),
                })}/>
            </div>
            <div className={'mb-2'}>
              <h6>Add buffer</h6>
              <Form.Control
                type="number"
                placeholder="Percents to add"
                value={newClusterParams?.buffer ?? ''}
                onChange={(e) => setNewClusterParams({
                  ...newClusterParams,
                  buffer: e.target.value && parseInt(e.target.value),
                })}/>
            </div>
          </div>
        </Col>
      </Row>


      {!evaluateResult.success && (
        <p className={'text-center text-muted'}>
          Click "Generate plot" to see a graph.
        </p>
      )}

      {evaluateResult?.image_base64 && (
        <div className={'mb-5'}>
          <Image src={evaluateResult.image_base64} fluid style={{ opacity: isAPILoading && '0.5' || '' }}/>
        </div>
      )}

      {crontabToRender && (
        <div>
          <h4>
            Crontab for curve
            "{allCurves.find(i => i.id === parseInt(selectedCurveId))?.name}"
            with params "{newClusterParams && JSON.stringify(newClusterParams)}"
          </h4>
          {crontabToRender.map((line, i) => (
            <div key={i}><code>{line}</code></div>
          ))}
        </div>
      )}
    </>
  );
}

export default EvaluateCurvePage;
