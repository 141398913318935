import logo from './logo.svg';
import './Home.css';
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to PoC of Load Predictor. It can:
        </p>
        <div>
          <p>- <Link to={'curves/build'}>Generate</Link> a normalized curve.</p>
          <p>- Compare a curve-based schedule with actual meetings count</p>
          <p>- Upload new curve to S3 so it can be read by Capacity Scheduler instances</p>
        </div>
      </header>
    </div>
  );
}

export default Home;
