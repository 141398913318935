import { Container, Nav, Navbar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { APIContext } from "../context/api";

function Menu() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const [apiMeta, setApiMeta] = useState({});

  const loadApiMeta = useCallback(() => {
    return fetchAPI({
      path: '/describe',
      query: {}
    }).then(response => {
      if (response?.success) setApiMeta(response);
    });
  }, []);

  useEffect(() => {
    loadApiMeta();
  }, []);

  return (
    <Navbar bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Link} to="/"><i>LoadPredictor</i></Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={Link} exact="true" to="/curves/build">Generate new curve</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/curves/evaluate">Evaluate curve</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/curves/manage">Manage</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/curves/github">Github Publish</Nav.Link>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <Nav.Link as={Link} exact="true" to="/archives">Archives</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="#">
            <Spinner animation="border" size="sm" style={{ opacity: isAPILoading ? 1 : 0 }}/>
          </Nav.Link>
          <Nav.Link href="#">
            {apiMeta.environment} / {apiMeta.region}
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Menu;
