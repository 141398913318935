import { createContext } from "react";

const env = require('env-var');

export const ConfigContext = createContext({
  // env variables names must start with REACT_APP_
  apiUrl: env.get('REACT_APP_API_URL')
    // for local development, set env var to http://127.0.0.1:5000/api/
    .default('/api/').asString()
    .replace(/\/$/, ''),
});
