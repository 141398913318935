import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Col, Form, Image, Modal, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { APIContext } from "../../context/api";
import { AlertContext } from "../../context/alert";
import confirm from "../confirm";
import { Link } from "react-router-dom";


function ZoomTimeslotModal({ onHide, content, title, ...props }) {
  return (
    <Modal
      {...props}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const intervalHelp = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">
      Interval (frequency)
    </Popover.Header>
    <Popover.Body>
      For now, use 30min only.
      Different intervals is for experimenting only and may not work correctly.
    </Popover.Body>
  </Popover>
);

const trimHelp = (
  <Popover id="popover-basic">
    <Popover.Body>
      <span>Trim outlier values in week-to-week values list. 0,2 == remove 20% smallest and largest values.</span>
      <span><br/><strong>To use max() instead of finding mean(), leave it empty</strong></span>
    </Popover.Body>
  </Popover>
);


function BuildCurvePage() {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  let [searchParams, setSearchParams] = useSearchParams();

  let defaultStartDate;
  let defaultEndDate;
  if (searchParams.get('endDate')) {
    defaultEndDate = new Date(Date.parse(`${searchParams.get('endDate')}T00:00:00+00:00`));
  } else {
    defaultEndDate = new Date();
  }
  if (searchParams.get('startDate')) {
    defaultStartDate = new Date(Date.parse(`${searchParams.get('startDate')}T00:00:00+00:00`));
  } else {
    defaultStartDate = new Date(defaultEndDate);
    defaultStartDate.setDate((defaultEndDate || new Date()).getDate() - 30);
  }

  const [curveData, setCurveData] = useState({});
  const [curveMetadata, setCurveMetadata] = useState({});
  const [cutFactor, setCutFactor] = useState(0.2);
  const [intervalValue, setIntervalValue] = useState('30min');
  const [curvePlotData, setCurvePlotData] = useState({});
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [timeslotData, setTimeslotData] = useState(null);

  const setCutFactorWithCheck = (value) => {
    if (value < 0.0) {
      value = 0.0;
    }
    if (value > 0.9) {
      value = 0.9;
    }
    setCutFactor(value);
  };

  const getZoomRange = () => {
    let result = [];
    if (curveData && curveData[1]) {
      Object.keys(curveData[1]).forEach((k) => {
        result.push(k);
      })
    }
    return result;
  };

  const getISOWeekdayRange = () => {
    const weekday = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    let result = [];
    for (let i = 1; i < 8; i++) {
      result.push({ number: i, name: weekday[i - 1] });
    }
    return result;
  };

  const generateCurve = () => {
    const startDateFmt = startDate.toISOString().substring(0, 10);
    const endDateFmt = endDate.toISOString().substring(0, 10);
    const sourcesFmt = 'all';

    return fetchAPI({
      path: '/generate_curve',
      query: {
        start_date: startDateFmt,
        end_date: endDateFmt,
        sources: sourcesFmt,
        cut_factor: cutFactor,
        interval: intervalValue,
      }
    }).then(response => {
      setCurveData(response.curve);
      setSearchParams({
        startDate: startDateFmt,
        endDate: endDateFmt,
      })
      setCurveMetadata(response?.metadata || {});
      return response.curve;
    });
  };

  const plotCurve = (curve) => {
    const curveDataStr = JSON.stringify(curve);
    fetchAPI({
      path: 'plot_curve',
      data: {
        curve_json: curveDataStr,
        interval: intervalValue,
      }
    }).then(response => setCurvePlotData(response));
  };

  const generateAndPlot = () => {
    generateCurve().then((curve) => curve && plotCurve(curve));
  }

  const saveCurve = async () => {
    const name = await confirm({
      title: `Saving curve`,
      input: true,
      inputDefaultValue: new Date().toISOString().substring(0, 19),
      confirmation: 'Name new curve',
    });
    if (!name) return;

    const curveDataStr = JSON.stringify(curveData);
    return fetchAPI({
      path: 'save_curve',
      data: {
        curve_json: curveDataStr,
        curve_name: name,
        interval: intervalValue,
        metadata: curveMetadata || {},
      },
    }).then((response) => {
      if (!response?.success) {
        return;
      }
      const curve = response.curve;
      showAlertMessage({
        variant: 'success', message: `Curve "${curve.name}" is created. ID: "${curve.id}"`,
      });
    })
  };

  const zoomTimeslot = (timeslot, weekday) => {
    const startDateFmt = startDate.toISOString().substring(0, 10);
    const endDateFmt = endDate.toISOString().substring(0, 10);

    fetchAPI({
      path: 'zoom_timeslot',
      query: {
        start_date: startDateFmt,
        end_date: endDateFmt,
        timeslot: timeslot,
        weekday_iso: weekday ?? 1,
        interval: intervalValue,
        cut_factor: cutFactor ?? null,
      },
    }).then(response => {
      setTimeslotData(response);
    });
  };

  const zoomTimeslotChangeWeekday = (weekday) => {
    zoomTimeslot(timeslotData.timeslot, parseInt(weekday));
  };

  return (<>
    <Row className={'mb-4'}>
      <Col lg="8">
        <p>Generate a new normalized curve from recent data. Once saved, <Link
          to={'/curves/evaluate'}>check</Link> how good it performs.</p>
      </Col>
      <Col>
        <div className={'float-end'}>
          {curvePlotData?.success && (<Button
            variant="success"
            onClick={saveCurve}
            disabled={isAPILoading}
            className={'me-2'}
          >
            {isAPILoading && (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />)}
            Save curve as
          </Button>)}
          <Button onClick={generateAndPlot} disabled={isAPILoading}>
            {isAPILoading && (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />)}
            Generate curve
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Row>
          <Col>
            <Row>
              <Col>
                <h6>Start date</h6>
                <DatePicker
                  className={'form-control'}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}/>
              </Col>
              <Col>
                <h6>End date</h6>
                <DatePicker
                  className={'form-control'}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}/>
              </Col>
            </Row>
          </Col>
          <Col>
            <h6>Cut Factor
              <OverlayTrigger trigger="click" placement="right" overlay={trimHelp}>
                <Button variant={'link'} size="sm" className={'help-btn'}>?</Button>
              </OverlayTrigger>
            </h6>
            <Form.Control
              type="number"
              placeholder="Cut Factor"
              step={0.05}
              aria-valuemax={0.9}
              aria-valuemin={0}
              value={cutFactor}
              onChange={(e) => setCutFactorWithCheck(e.target.value)}/>
          </Col>
          <Col>
            <h6>
              Interval
              <OverlayTrigger trigger="click" placement="right" overlay={intervalHelp}>
                <Button variant={'link'} size="sm" className={'help-btn'}>?</Button>
              </OverlayTrigger>
            </h6>
            <Form.Select value={intervalValue} onChange={(e) => setIntervalValue(e.target.value)}>
              <option value="5min">5min</option>
              <option value="30min">30min</option>
              <option value="60min">60min</option>
            </Form.Select>
          </Col>
          <Col>
            <h6>Sources</h6>
            <Form.Check
              type="switch"
              id="al-archive"
              label="Audio Ledgers Archive"
              defaultChecked={true}
            />
            <Form.Check
              type="switch"
              id="webex"
              label="WebEx meetings (TODO)"
              disabled={true}
            />
            <Form.Check
              type="switch"
              id="scheduled"
              label="Scheduled calls (TODO)"
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {!curvePlotData.success && (
              <p className={'text-center text-muted'}>
                Click "Generate curve" to see a curve plot.
              </p>
            )}

            {!!curvePlotData?.image_base64 && (
              <Image fluid src={curvePlotData.image_base64} alt='graph'
                     style={{ opacity: isAPILoading && '0.5' || '' }}/>
            )}

            {!!curvePlotData?.image_base64 && (
              <div className={'text-center mt-5 mb-5'}>
                <h6>Zoom time slot</h6>
                <p>
                  {getZoomRange().map(i => (
                    <Button
                      key={i}
                      className={'me-1 mb-1'}
                      variant={'outline-secondary'}
                      onClick={() => zoomTimeslot(i)}
                    >
                      {i}
                    </Button>
                  ))}
                </p>
              </div>
            )}
            {curvePlotData?.crontab && (<div className={'mt-4'}>
              <h4>Normalized cron schedule (not intended to be used as is)</h4>
              <br/>
              {curvePlotData.crontab.map((line, i) => {
                return (
                  <pre key={i} className={'mb-0'}>{line}</pre>
                );
              })}
            </div>)}
          </Col>
        </Row>
      </Col>
    </Row>
    <ZoomTimeslotModal
      show={!!timeslotData?.image_base64}
      onHide={() => setTimeslotData(null)}
      title={timeslotData?.name}
      content={
        <div className={'text-center'}>
          <Image
            fluid
            src={timeslotData?.image_base64}
            alt={timeslotData?.name}
            style={{ opacity: isAPILoading && '0.5' || '' }}
          />
          <div>
            {getISOWeekdayRange().map((item) => (
              <Button
                key={item.number}
                variant={timeslotData?.weekday === item.number && 'secondary' || 'outline-secondary'}
                className={'me-2'}
                onClick={() => zoomTimeslotChangeWeekday(item.number)}
                disabled={isAPILoading}
              >
                {item.name}
              </Button>
            ))}
          </div>
        </div>
      }
    />
  </>);
}

export default BuildCurvePage;
