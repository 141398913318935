import React, { createContext, useContext, useState } from "react";
import { ConfigContext } from './config';
import { AlertContext } from "./alert";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export const APIContext = createContext({
  isAPILoading: false,
  fetchAPI: () => {
  },
});

const AuthErrorAlert = ({ response, onClose }) => {
  return (
    <div>
      <div>{response?.detail}</div>
      <Link to="/login" >
        <Button onClick={onClose}>Login</Button>
      </Link>
    </div>
  )
};

export const APIProvider = ({ children }) => {
  const [isAPILoading, setIsAPILoading] = useState(false);
  const { apiUrl } = useContext(ConfigContext);
  const { showAlertMessage, hideAlertMessage } = useContext(AlertContext);

  const fetchAPI = ({ path, data, query, checkSuccess = true, noLoading = false }) => {
    path = path.replace(/\/$/, '');
    path = path.replace(/^\//, '');
    const baseUrl = apiUrl.replace(/\/$/, '');
    let url = `${baseUrl}/${path}`;

    if (query) {
      url += '?';
      url += new URLSearchParams(query);
    }

    const authorizationHeader = localStorage.getItem("auth_header") || null;
    let params = {
      method: !data ? "GET" : "POST",
      credentials: 'omit',
      headers: new Headers({
        Accept: "application/json",
        'Content-Type': "application/json",
        'Authorization': authorizationHeader,
      }),
    };
    if (data) {
      params.body =  JSON.stringify(data);
    }

    if (!noLoading) {
      setIsAPILoading(true);
    }
    return fetch(
      url,
      params)
      .then(async (res) => {
        const response = await res.json();
        if (res.status === 401) {
          showAlertMessage({
            message: <AuthErrorAlert response={response} onClose={() => hideAlertMessage()} />,
            variant: 'danger',
            title: 'Auth error',
          });
        } else if (response?.success === false) {
          showAlertMessage({ message: response.error, variant: 'warning', title: 'Request error' });
        } else {
          hideAlertMessage();
        }
        return response;
      })
      .catch(error => {
        console.log(error);
        showAlertMessage({ message: error?.message, variant: 'danger', title: 'API Error' });
      })
      .finally(() => setIsAPILoading(false));
  }

  return (
    <APIContext.Provider value={{ fetchAPI, isAPILoading }}>
      {children}
    </APIContext.Provider>
  );
}
