import { Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  const loginWithCreds = () => {
    localStorage.setItem("auth_header", 'Basic ' + btoa(`${username}:${password}`));
    window.location.href = '/';
  };

  return (
    <Row className={'mt-4'}>
      <Col lg={6}>
        <p className={'text-muted'}>Look at k8s_config</p>

        <h6>Username</h6>
        <Form.Control
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}/>
        <h6>Password</h6>
        <Form.Control
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}/>
        <div className={'mt-3'}>
          <Button variant={'success'} onClick={loginWithCreds}>Sign in</Button>
        </div>
      </Col>
    </Row>
  )
}

export default LoginPage;
