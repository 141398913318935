import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/home/Home";
import NoPage from "./components/NoPage";
import BuildCurvePage from "./components/curves/BuildCurvePage";
import EvaluateCurvePage from "./components/curves/EvaluateCurvePage";
import ManageCurvesPage from "./components/curves/ManageCurvesPage";
import ArchivesPage from "./components/archives/ArchivesPage";
import GithubPublishPage from "./components/curves/GithubPublishPage";
import LoginPage from "./components/auth/LoginPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout fluid/>}>
        <Route index element={<Home/>}/>
        <Route path="*" element={<NoPage/>}/>
        <Route path="/login" element={<LoginPage/>}/>
      </Route>
      <Route path="/curves" element={<Layout/>}>
        <Route path="/curves/build" element={<BuildCurvePage/>}/>
        <Route path="/curves/evaluate" element={<EvaluateCurvePage/>}/>
        <Route path="/curves/manage" element={<ManageCurvesPage/>}/>
        <Route path="/curves/github" element={<GithubPublishPage/>}/>
      </Route>
      <Route path="/archives" element={<Layout/>}>
        <Route path="/archives" element={<ArchivesPage/>}/>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
