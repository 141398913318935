import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Col, Image, Modal, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';

import confirm from "../confirm";
import { APIContext } from "../../context/api";
import { AlertContext } from "../../context/alert";


function VisualizeModal({ onHide, content, title, ...props }) {
  return (
    <Modal
      {...props}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


const makeActiveHelp = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">
      Active curve
    </Popover.Header>
    <Popover.Body>
      By clicking <b>Make active</b>, you make selected curve uploaded
      to S3 at location for default curve.
      <br/>
      E.g. <code>/active/cprod/AMER.json</code>
      <br/>
      Later, this <code>AMER.json</code> could be consumed by Capacity Management.
      <br/><br/>
      TODO: Active curve also updates schedule at values.yaml file?
    </Popover.Body>
  </Popover>
);



function ManageCurvesPage() {
  const navigate = useNavigate();
  const [savedCurves, setSavedCurves] = useState([]);
  const [activeCurve, setActiveCurve] = useState({});
  const [curvePlotData, setCurvePlotData] = useState(null);
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const loadSavedCurves = useCallback(() => {
    fetchAPI({ path: 'list_curves' }).then((response) => {
      if (!response?.curves) {
        return;
      }
      setSavedCurves(response?.curves);
    });
  }, []);

  const makeActive = async ({ curveId, name }) => {
    const result = await confirm({
      confirmation: `Are you sure you want to make "${name}" active?`
    });
    if (!result) return;
    return fetchAPI({
      path: 'set_curve_active',
      query: { curve_id: curveId },
      data: {},
    }).then((response) => {
      if (response.success) {
        showAlertMessage({
          message: `Curve "${activeCurve.name}" is now active. Capacity Scheduler will use it for
              setting capacity for clusters in "${activeCurve.region}" via Capacity Manager.`,
          variant: 'success',
        });
        setActiveCurve(savedCurves.find((c) => c.id === curveId));
        refresh();
      }
    })
  };

  const visualizeCurve = ({ id, name, curve_metadata }) => {
    return fetchAPI({
      path: '/plot_curve',
      data: {
        curve_id: id,
      }
    }).then((response) => {
      if (!response) return;
      setCurvePlotData({
        name,
        curve_metadata,
        ...response,
      });
    });
  };

  const deleteCurve = async ({ curveId, name }) => {
    const result = await confirm({ confirmation: `Are you sure you want to delete ${name}?` });
    if (!result) return;

    return fetchAPI({
      path: '/delete_curve',
      query: {
        curve_id: curveId,
      },
      data: {},
    }).then((response) => {
      response?.success && refresh();
    });
  };

  const refresh = useCallback(() => {
    loadSavedCurves();
  }, [loadSavedCurves]);

  const showS3Info = ({ s3_full_path, s3_active_full_path }) => {
    confirm({
      title: 'Object path',
      confirmation: (
        <div>
          <code>{s3_full_path}</code>
          {!!s3_active_full_path && (
            <div>
              <div className={'mb-2 mt-2'}>Copy (as active curve for region):</div>
              <code>{s3_active_full_path}</code>
            </div>
          )}
        </div>
      ),
    })
  }

  useEffect(() => {
    loadSavedCurves();
  }, [loadSavedCurves]);

  return (
    <div>
      <Row className={'pb-3 align-content-end'}>
        <Col>
          <p>
            List of saved curves and curve currently used (active) for Capacity Management
          </p>
        </Col>
        <Col>
          <Button
            className={'float-end'}
            onClick={refresh}
            disabled={isAPILoading}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <VisualizeModal
        show={!!curvePlotData}
        onHide={() => setCurvePlotData(null)}
        title={curvePlotData?.name}
        content={
          <div>
            <div>
              <code>{JSON.stringify(curvePlotData?.curve_metadata ?? {})}</code>
            </div>
            <Image fluid src={curvePlotData?.image_base64} alt={curvePlotData?.name} style={{ opacity: isAPILoading && '0.5' || '' }}/>
          </div>
        }
      />

      <Table striped bordered hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Region</th>
          <th>Date created</th>
          <th>
            Active
            <OverlayTrigger trigger="click" placement="right" overlay={makeActiveHelp}>
              <Button variant={'link'} size="sm" className={'help-btn'}>?</Button>
            </OverlayTrigger>
          </th>
          <th>S3</th>
          <th>Local file</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {savedCurves?.map((item, i) => {
          return (<tr key={i}>
            <td>
              {item.id}
            </td>
            <td>
              {item.name}
              {item.freq && item.freq !== '30min' && (
                <span className={'text-danger'}>&nbsp; ({item.freq})</span>
              )}
            </td>
            <td>{item.region}</td>
            <td>
              <span title={new Date(item.created_at).toString()}>
                {new Date(item.created_at).toLocaleString('en-US')}
              </span>
            </td>
            <td>
              {item.is_active && (<span>&#9989;</span>)}
              {!item.is_active && (
                <Button
                  variant={'outline-info'}
                  onClick={() => makeActive({ curveId: item.id, name: item.name })}
                  disabled={isAPILoading}
                >
                  Make active
                </Button>
              )}
            </td>
            <td>
              {item.is_s3_uploaded && (
                <Button variant={'outline-secondary'} onClick={() => showS3Info(item)}>&#9745;</Button>
              )}
            </td>
            <td>
              {item.is_artifacts && (<span>&#9745;</span>)}
              {!item.is_artifacts && (<span>-</span>)}
            </td>
            <td>
              <Button
                variant={'secondary'}
                onClick={() => visualizeCurve(item)}
                disabled={isAPILoading}
                className={'me-2'}
              >
                Visualize
              </Button>
              <Button
                onClick={() => navigate(`/curves/evaluate?curveId=${item.id}`)}
                disabled={isAPILoading}
                className={'me-2'}
              >
                Evaluate
              </Button>
              <Button
                variant={'outline-danger'}
                onClick={() => deleteCurve({ curveId: item.id, name: item.name })}
                disabled={isAPILoading}
              >
                Delete
              </Button>
            </td>
          </tr>);
        })}
        </tbody>
      </Table>

      {(!savedCurves.length && !isAPILoading) && (
        <div className={'text-center'}>
          <Link to="/curves/build">Generate new curve</Link>
        </div>
      )}
    </div>
  );
}

export default ManageCurvesPage;
