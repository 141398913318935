import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { APIContext } from "../../context/api";

function GithubPublishPage() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const [createPRResult, setCreatePRResult] = useState(null);
  const [allCurves, setAllCurves] = useState([]);
  const [valuesFiles, setValuesFiles] = useState([]);
  const [selectedCurveId, setSelectedCurveId] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [scaleFactor, setScaleFactor] = useState('');
  const [isDryRun, setIsDryRun] = useState(false);
  const [selectedUpdatedMethod, setSelectedUpdateMethod] = useState('curve');

  const loadCurves = useCallback(() => {
    return fetchAPI({
      path: '/list_curves', query: {}
    }).then(response => {
      setAllCurves(response.curves);
      return response.curves;
    });
  }, []);

  const loadValuesFiles = useCallback(() => {
    return fetchAPI({
      path: '/list_values_files', query: {}
    }).then(response => {
      setValuesFiles(response.values_files);
      return response.values_files;
    });
  }, []);


  useEffect(() => {
    loadCurves();
    loadValuesFiles();
  }, [loadCurves]);

  const createGithubPR = () => {
    fetchAPI({
      path: 'github_publish',
      data: {
        method: selectedUpdatedMethod,
        curve_id: selectedCurveId || null,
        min_value: minValue === "" ? null : minValue,
        max_value: maxValue === "" ? null : maxValue,
        scale_factor: scaleFactor === "" ? null : scaleFactor,
        dry_run: !!isDryRun,
      },
    }).then(response => {
      if (!response.success) {
        return response;
      }
      setCreatePRResult(response);
    })
  };

  return (<div>
    <Row className={'pb-3 align-content-end'}>
      <Col>
        <p>
          Update values.yaml files on github
        </p>
        <p className={'text-muted'}>
          New PR to k8s_config will be created. You'll need to merge it manually.
        </p>
      </Col>
      <Col>
      </Col>
    </Row>

    <Row>
      <Col>
        <h5>Update method:</h5>
        <Form.Select
          size="md"
          value={selectedUpdatedMethod || ''}
          onChange={(e) => setSelectedUpdateMethod(e.target.value)}
          className={'mb-2'}
        >
          <option value="curve">Update with selected curve</option>
          <option value="minmax">Rewrite existing crontab with new min/max</option>
          <option value="scalefactor">Scale existing crontab to a factor</option>
        </Form.Select>


        {selectedUpdatedMethod === 'curve' && (
          <div>
            <h6>Curve:</h6>
            <Form.Select
              size="md"
              value={selectedCurveId || ''}
              onChange={(e) => setSelectedCurveId(e.target.value)}
              className={'mb-2'}
            >
              <option value="">-</option>
              {allCurves?.map((item => (
                <option value={item.id} key={item.id}>
                  {item.is_active && ("[Active] ")}
                  {item.name}
                </option>
              )))}
            </Form.Select>
          </div>
        )}

        {(selectedUpdatedMethod === 'minmax' || selectedUpdatedMethod === 'curve') && (
          <div>
            <div className={'mb-2'}>
              <h6>Min Value</h6>
              <Form.Control
                type="number"
                placeholder="Min Value"
                value={minValue}
                onChange={(e) => setMinValue(e.target.value && parseInt(e.target.value))}/>
            </div>

            <div className={'mb-2'}>
              <h6>Max Value</h6>
              <Form.Control
                type="number"
                placeholder="Max Value"
                value={maxValue}
                onChange={(e) => setMaxValue(e.target.value && parseInt(e.target.value))}/>
            </div>
          </div>
        )}

        {selectedUpdatedMethod === 'scalefactor' && (
          <div className={'mb-2'}>
            <h6>Scale Factor</h6>
            <p className={'text-muted'}>E.g. if factor=1.2, current=100, then result=120</p>
            <Form.Control
              type="number"
              placeholder="Scale factor"
              step={0.05}
              aria-valuemax={2}
              aria-valuemin={0.05}
              value={scaleFactor}
              onChange={(e) => setScaleFactor(e.target.value && parseFloat(e.target.value))}/>
          </div>
        )}

        <div className={'mt-4'}>
          <h5>Values files to be updated (if exist and valid):</h5>
          <div className={'text-muted'}>
            To modify list of files to be updated, update <code>config.yaml</code> in sloty-load-predictor repo.
          </div>
          {valuesFiles?.map((filename, i) => (<span key={i}>
                <code>{filename}</code>
                <br/>
              </span>))}
        </div>

        {!createPRResult?.success && (<div>
          <Button
            className={'mt-5 mb-2'}
            onClick={createGithubPR}
            disabled={isAPILoading}
          >
            Create PR with update to values.yaml
          </Button>
          <Form.Check
            type="switch"
            id="dryrun"
            label="Dry run (don't create PR, see pods logs)"
            value={isDryRun}
            onChange={(e) => setIsDryRun(e.target.value)}
          />
        </div>)}

        <div className={'mt-4'}>
          {!!createPRResult?.pr_url && (
            <a href={createPRResult.pr_url} target={'_blank'}>{createPRResult.pr_url}</a>)}
          {!!createPRResult?.result_message && (
            <h4 className={'text-warning mt-2 mb-2'}>{createPRResult.result_message}</h4>)}
        </div>
      </Col>
    </Row>
  </div>);
}

export default GithubPublishPage;
