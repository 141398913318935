import { useCallback, useContext, useEffect, useState } from "react";
import TimeAgo from 'javascript-time-ago'
import { Table } from "react-bootstrap";
import { APIContext } from "../../context/api";
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en');


function ArchivesPage() {
  const { fetchAPI } = useContext(APIContext);
  const [rollups, setRollups] = useState([]);

  const loadArchives = useCallback(() => {
    fetchAPI({
      path: 'list_archives',
      query: {},
    }).then(response => response?.success && setRollups(response.rollups));
  }, []);

  useEffect(() => {
    loadArchives();
  }, []);

  return (
    <Table striped bordered hover size="sm">
      <thead>
      <tr>
        <th>ID</th>
        <th>Cluster</th>
        <th>Type</th>
        <th>First collected</th>
        <th>Latest collected</th>
      </tr>
      </thead>
      <tbody>
      {rollups?.map((item) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.cluster}</td>
          <td>{item.type}</td>
          <td>
            {item.first_aggregated_datetime}
            {!!item.first_aggregated_datetime && (
              <p>{timeAgo.format(new Date(`${item.first_aggregated_datetime}+00:00`))}</p>
            )}
          </td>
          <td>
            {item.last_aggregated_datetime}
            {!!item.last_aggregated_datetime && (
              <p>{timeAgo.format(new Date(`${item.last_aggregated_datetime}+00:00`))}</p>
            )}
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  )
}

export default ArchivesPage;
